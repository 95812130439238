body{
  background-color: #FEEAC4;
  color: hsl(355deg 83% 43%);;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



@media all and (max-width:29.99em){
  .App{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: fit-content;
  }
  
  .Images{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* right: 10vw; */
  }
  
  .Images img{
    width: 70%;
    height: auto;
  }
  
  .second{
    position: relative;
    right: 3vw;
  }
  
  .first{
    width: 60% !important;
  }
  
  .Forms{
    position: relative;
    left: 5vw;
    width: 70%;
  }
  
  .Forms h2{
    font-size: 5vw;
    text-align: center;
  }

  .Forms h3{
    font-size: 3vw;
    text-align: center;
    font-weight: 450;
  }
  
  .form1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
  }
  
  .form2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
    position: relative;
    bottom: 5vw;
  }
  
  .show{
    opacity: 1;
  }
  
  .form1 .tweetButton{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 2vw 5.5vw 2vw 5.5vw;
    font-size: 3.5vw;
    border-radius: 3.5vw;
  }
  
  input[name="TweetLink"]{
    width: 100%;
    border: thin solid #FEEAC4;
    padding: 3vw 0vw 3vw 5vw;
    background-color: hsl(355deg 83% 43%);
    border-radius: 5vw;
    font-size: 4vw;
    color: #FEEAC4;
  }
  
  input[name="TweetLink"]::placeholder{
    color: #FEEAC4;
  }
  
  .Next{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 2vw 5.5vw 2vw 5.5vw;
    font-size: 3.5vw;
    border-radius: 3.5vw;
  }
  
  button:disabled,
  button[disabled]{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 2vw 5.5vw 2vw 5.5vw;
    font-size: 3.5vw;
    border-radius: 3.5vw;
    opacity: 0.3;
  }
  
  .error{
    font-size: 3vw;
    position: relative;
    bottom: 2vw;
    visibility: hidden;
  }
  
  .show1{
    visibility: visible;
  }
  
  .form2 button{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    padding: 2vw 5.5vw 2vw 5.5vw;
    font-size: 3.5vw;
    border-radius: 3.5vw;
    margin-bottom: 1.2vw;
  }
}

@media (min-width:30em) and (max-width:47.99em){
  .App{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: fit-content;
  }
  
  .Images{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* right: 10vw; */
  }
  
  .Images img{
    width: 70%;
    height: auto;
  }
  
  .second{
    position: relative;
    right: 3vw;
  }
  
  .first{
    width: 60% !important;
  }
  
  .Forms{
    position: relative;
    left: 5vw;
    width: 70%;
  }
  
  .Forms h2{
    font-size: 3vw;
    text-align: center;
  }

  .Forms h3{
    font-size: 2.5vw;
    text-align: center;
    font-weight: 450;
  }
  
  .form1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
  }
  
  .form2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
    position: relative;
    bottom: 5vw;
  }
  
  .show{
    opacity: 1;
  }
  
  .form1 .tweetButton{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 1.5vw 5vw 1.5vw 5vw;
    font-size: 2vw;
    border-radius: 3vw;
  }
  
  input[name="TweetLink"]{
    width: 100%;
    border: thin solid #FEEAC4;
    padding: 2vw 0vw 2vw 3vw;
    background-color: hsl(355deg 83% 43%);
    border-radius: 5vw;
    font-size: 2vw;
    color: #FEEAC4;
  }
  
  input[name="TweetLink"]::placeholder{
    color: #FEEAC4;
  }
  
  .Next{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 1.5vw 5vw 1.5vw 5vw;
    font-size: 2vw;
    border-radius: 3vw;
  }
  
  button:disabled,
  button[disabled]{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 1.5vw 5vw 1.5vw 5vw;
    font-size: 2vw;
    border-radius: 3vw;
    opacity: 0.3;
  }
  
  .error{
    font-size: 3vw;
    position: relative;
    bottom: 2vw;
    visibility: hidden;
  }
  
  .show1{
    visibility: visible;
  }
  
  .form2 button{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    padding: 1.5vw 5vw 1.5vw 5vw;
    font-size: 2vw;
    border-radius: 3vw;
    margin-bottom: 1.2vw;
  }
}

@media (min-width:48em) and (max-width:62em){
  .App{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: fit-content;
  }
  
  .Images{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 8vw;
    /* right: 10vw; */
  }
  
  .Images img{
    width: 70%;
    height: auto;
  }
  
  .second{
    position: relative;
    right: 3vw;
  }
  
  .first{
    width: 60% !important;
  }
  
  .Forms{
    position: relative;
    left: 5vw;
    width: 40%;
  }
  
  .Forms h2{
    font-size: 2.1vw;
  }

  .Forms h3{
    font-size: 1.9vw;
    font-weight: 450;
  }
  
  .form1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
  }
  
  .form2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
    position: relative;
    bottom: 5vw;
  }
  
  .show{
    opacity: 1;
  }
  
  .form1 .tweetButton{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.8vw 2.2vw 0.8vw 2.2vw;
    font-size: 1.8vw;
    border-radius: 2vw;
  }
  
  input[name="TweetLink"]{
    width: 100%;
    border: thin solid #FEEAC4;
    padding: 1vw 0vw 1vw 2vw;
    background-color: hsl(355deg 83% 43%);
    border-radius: 5vw;
    color: #FEEAC4;
    font-size: 1.4vw;
  }
  
  input[name="TweetLink"]::placeholder{
    color: #FEEAC4;
  }
  
  .Next{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.8vw;
    border-radius: 2vw;
  }
  
  button:disabled,
  button[disabled]{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.8vww;
    border-radius: 2vw;
    opacity: 0.3;
  }
  
  .error{
    font-size: 2vw;
    position: relative;
    bottom: 2vw;
    visibility: hidden;
  }
  
  .show1{
    visibility: visible;
  }
  
  .form2 button{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.8vw;
    border-radius: 2vw;
    margin-bottom: 1.2vw;
  }
}

@media (min-width:62em) and (max-width:75em){
  .App{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: fit-content;
  }
  
  .Images{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 6vw;
    /* right: 10vw; */
  }
  
  .Images img{
    width: 70%;
    height: auto;
  }
  
  .second{
    position: relative;
    right: 3vw;
  }
  
  .first{
    width: 60% !important;
  }
  
  .Forms{
    position: relative;
    left: 5vw;
    width: 40%;
  }
  
  .Forms h2{
    font-size: 1.7vw;
  }

  .Forms h3{
    font-size: 1.5vw;
    font-weight: 450;
  }
  
  .form1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
  }
  
  .form2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
    position: relative;
    bottom: 5vw;
  }
  
  .show{
    opacity: 1;
  }
  
  .form1 .tweetButton{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.7vw 2.3vw 0.7vw 2.3vw;
    font-size: 1.3vw;
    border-radius: 2vw;
  }
  
  input[name="TweetLink"]{
    width: 100%;
    border: thin solid #FEEAC4;
    padding: 1vw 0vw 1vw 2vw;
    background-color: hsl(355deg 83% 43%);
    border-radius: 5vw;
    color: #FEEAC4;
    font-size: 1.1vw;
  }
  
  input[name="TweetLink"]::placeholder{
    color: #FEEAC4;
  }
  
  .Next{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1vw;
    border-radius: 2vw;
  }
  
  button:disabled,
  button[disabled]{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.7vw 2.8vw 0.7vw 2.8vw;
    font-size: 1.3vw;
    border-radius: 2vw;
    opacity: 0.3;
  }
  
  .error{
    font-size: 2vw;
    position: relative;
    bottom: 2vw;
    visibility: hidden;
  }
  
  .show1{
    visibility: visible;
  }
  
  .form2 button{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    padding: 0.7vw 2.8vw 0.7vw 2.8vw;
    font-size: 1.3vw;
    border-radius: 2vw;
    margin-bottom: 1.2vw;
  }
}

@media all and (min-width:75em){
  .App{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: fit-content;
  }
  
  .Images{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 5vw;
    /* right: 10vw; */
  }
  
  .Images img{
    width: 70%;
    height: auto;
  }
  
  .second{
    position: relative;
    right: 3vw;
  }
  
  .first{
    width: 60% !important;
  }
  
  .Forms{
    position: relative;
    left: 5vw;
    width: 40%;
  }
  
  .Forms h2{
    font-size: 1.6vw;
  }

  .Forms h3{
    font-size: 1.4vw;
    font-weight: 490;
  }
  
  .form1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
  }
  
  .form2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    transition: .5s all ease-in;
    position: relative;
    bottom: 5vw;
  }
  
  .show{
    opacity: 1;
  }
  
  .form1 .tweetButton{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2vw 0.5vw 2vw;
    font-size: 1.2vw;
    border-radius: 2vw;
  }
  
  input[name="TweetLink"]{
    width: 100%;
    border: thin solid #FEEAC4;
    padding: 1vw 0vw 1vw 2vw;
    background-color: hsl(355deg 83% 43%);
    border-radius: 5vw;
    color: #FEEAC4;
    font-size: 1.2vw;
  }
  
  input[name="TweetLink"]::placeholder{
    color: #FEEAC4;
  }
  
  .Next{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.2vw;
    border-radius: 2vw;
  }
  
  button:disabled,
  button[disabled]{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    margin: 1vw 1vw 2vw 0vw;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.2vw;
    border-radius: 2vw;
    opacity: 0.3;
  }
  
  .error{
    font-size: 1vw;
    position: relative;
    bottom: 2vw;
    visibility: hidden;
  }
  
  .show1{
    visibility: visible;
  }

  .form2 h3{
    font-size: 2vw;
  }
  
  .form2 button{
    background: hsl(355deg 83% 43%);
    color: #FEEAC4;
    border: none;
    padding: 0.5vw 2.5vw 0.5vw 2.5vw;
    font-size: 1.3vw;
    border-radius: 2vw;
    margin-bottom: 1.2vw;
  }
}
